import React, { useState, useEffect } from 'react';
import { Box, SlideFade, Heading, Container } from '@chakra-ui/react';

const Hero = ({ title }) => {
  const [start, setStart] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 500);
  }, []);

  return (
    <Container maxWidth="container.xl" paddingX="0">
      <Box
        className="hero"
        paddingY={{ base: '10rem', lg: '24rem' }}
        bgColor="gray.100"
      >
        <SlideFade
          in={start}
          offsetY="5rem"
          transition={{ enter: { duration: 1 } }}
        >
          <Heading
            as="h1"
            fontSize={{ base: '2xl', lg: '9xl' }}
            textAlign="center"
            letterSpacing="-0.15rem"
            paddingX={{ base: '2rem', lg: '10rem' }}
          >
            {title}
          </Heading>
        </SlideFade>
      </Box>
    </Container>
  );
};

export default Hero;
