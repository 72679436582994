import React from 'react';
import { Flex, Box, Text, useBreakpointValue } from '@chakra-ui/react';
import Lottie from 'lottie-react';

const FeatureCard = ({ eyebrow, title, lottieFile, reverse = false }) => {
  const isLarge = useBreakpointValue({ base: false, lg: true });

  const children = [
    <Flex
      key="content"
      flex="1"
      justifyContent="center"
      flexDirection="column"
      marginBottom={{ base: '3rem', lg: 0 }}
    >
      <Box>
        <Text
          fontSize={{ base: 'sm', lg: 'sm' }}
          fontWeight="bold"
          textTransform="uppercase"
          color="gray.600"
          marginBottom="1rem"
        >
          {eyebrow}
        </Text>
      </Box>
      <Text
        fontSize={{ base: 'md', lg: 'xl' }}
        fontWeight="bold"
        paddingBottom={{ base: '', lg: '20%' }}
      >
        {title}
      </Text>
    </Flex>,
    lottieFile && (
      <Box key="lottie" flex="1">
        <Lottie animationData={lottieFile} autoplay loop />
      </Box>
    ),
  ];

  return (
    <Flex
      className="FeatureCard"
      flexDirection={{ base: 'column', lg: 'row' }}
      paddingY={{ base: '4rem', lg: '8rem' }}
      sx={{
        '&>div:first-child': {
          paddingRight: { base: 0, lg: '2rem' },
        },
      }}
    >
      {reverse && isLarge ? children.reverse() : children}
    </Flex>
  );
};

export default FeatureCard;
