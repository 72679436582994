import React from 'react';
import {
  Box,
  Flex,
  Container,
  Image,
  Wrap,
  WrapItem,
  Link,
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout';
import Hero from '../components/hero';
import FeatureCard from '../components/feature-card';

import appMenuSrc from '../images/app-menu.jpg';
import appCreditSrc from '../images/app-credit.jpg';
import appPlanSrc from '../images/app-plan.jpg';
import appConversionSrc from '../images/app-conversion.jpg';
import playStoreSrc from '../images/play-store.png';
import appStoreSrc from '../images/app-store.png';

import checklistJson from '../assets/lottie-files/checklist.json';
import totalInterestJson from '../assets/lottie-files/totalinterests.json';

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title="CreditCalculator" description={t('home:page-description')}>
      <Hero title={t('home:title')} />
      <Container maxWidth="container.xl">
        <FeatureCard
          lottieFile={totalInterestJson}
          reverse
          eyebrow={t('home:feature-card-0.eyebrow')}
          title={t('home:feature-card-0.title')}
        />
        <FeatureCard
          lottieFile={checklistJson}
          eyebrow={t('home:feature-card-1.eyebrow')}
          title={t('home:feature-card-1.title')}
        />
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'center', lg: 'center' }}
          justifyContent={{ base: 'center', lg: 'center' }}
        >
          <Link
            href="https://play.google.com/store/apps/details?id=com.creditcalculator"
            marginRight={{ base: '0', lg: '2rem' }}
            marginBottom={{ base: '2rem', lg: '0' }}
            target="_blank"
            rel="noopener"
          >
            <Image
              src={playStoreSrc}
              alt="play store"
              objectFit="contain"
              height="10rem"
            />
          </Link>
          <Link
            href="https://apps.apple.com/us/app/credit-calculator-app/id1576697884"
            marginRight={{ base: '0', lg: '2rem' }}
            marginBottom={{ base: '2rem', lg: '0' }}
          >
            <Image
              src={appStoreSrc}
              alt="app store"
              objectFit="contain"
              height="10rem"
            />
          </Link>
        </Flex>
        <Box className="Feature" marginY={{ base: '4rem', lg: '8rem' }}>
          <Wrap
            spacing="1rem"
            justify={{ lg: 'space-between' }}
            align={{ base: 'center', lg: 'flex-start' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <WrapItem>
              <Image
                src={appMenuSrc}
                alt="CreditCalculator App Menu"
                objectFit="contain"
                height="60rem"
              />
            </WrapItem>
            <WrapItem>
              <Image
                src={appCreditSrc}
                alt="CreditCalculator App new credit screen"
                objectFit="contain"
                height="60rem"
              />
            </WrapItem>
            <WrapItem>
              <Image
                src={appPlanSrc}
                alt="CreditCalculator App payment plan screen"
                objectFit="contain"
                height="60rem"
              />
            </WrapItem>
            <WrapItem>
              <Image
                src={appConversionSrc}
                alt="CreditCalculator App conversion screen"
                objectFit="contain"
                height="60rem"
              />
            </WrapItem>
          </Wrap>
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "home"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
